import Vue from 'vue';
import store from '@/store';
import '@/util/SaveUtil';
import '@/util/save.js';
import './param';
import './popup';
import './plugins/axios';
import '@/config/sheet';
import App from '@/App.vue';
import router from '@/config/router';
import {mapGetters, mapActions} from 'vuex';
import 'vue-slim-tabs/themes/default.css';
import * as Tabs from 'vue-slim-tabs';
import kebabCase from 'lodash/kebabCase';
import cloneDeep from 'lodash/cloneDeep';
import '@/assets/css/style.scss';
import elementClosest from 'element-closest';
import VModal from 'vue-js-modal';
import ModalStrategy from './modalStrategy';
import MenuConstants from './constants/menuConstants';
import CodeConstants from './constants/codeConstants';

import IuiVPathPlugin from './plugins/IuiVPath';
import IuiValidatorPlugin from './plugins/IuiValidator';
import VueSecuKitNXHelper from '@inogard-dev/vue-secukitnx-helper';
import VueIBSheetHelper from './plugins/vue-ibsheet-helper'; // VueIBSheetHelper
import Viewer from 'v-viewer';

// filters
import {number as numberFilter, currency as currencyFilter} from './components/Iui/filters';

import {childViewAuthSetup} from './menuAuth';
import {getComponent} from './common';
import {validParameter, validSheet} from '@/util/validUtil.js';

elementClosest(window);
const bus = new Vue();
Vue.config.productionTip = false;
Vue.use(IuiVPathPlugin);
Vue.use(IuiValidatorPlugin);
Vue.use(VueSecuKitNXHelper);
// Vue.use(VueSecuKitNXHelper, {checkReadyWhenInit: true});
Vue.use(VueIBSheetHelper, {}); // VueIBSheetHelper 플러그인 설치
Vue.use(Tabs);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true, dialog: true, dynamicDefaults: {clickToClose: false}});
Vue.use(ModalStrategy);
Vue.use(Viewer);

Vue.filter('number', numberFilter);
Vue.filter('quantity', numberFilter);
Vue.filter('rate', numberFilter);
Vue.filter('currency', currencyFilter);
Vue.filter('amount', currencyFilter);
Vue.filter('unitAmount', currencyFilter);

/**
 * Object.entries Polyfill
 *
 * @see https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
 */
if (!Object.entries) {
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (sessionStorage.getItem('accessToken')) {
  store.commit('jwt/setAccessToken', sessionStorage.getItem('accessToken'));
  store.commit('jwt/setRefreshToken', sessionStorage.getItem('refreshToken'));
}

window.$init = (that, store, module) => {
  const namespace = `${that.$store.getters.getStoreNm}${module ? '/' + module : ''}`;
  if (store) {
    if (that.$store.getters.modules.indexOf(namespace.replace(/\//, '')) == -1) {
      const _store = cloneDeep(store);
      that.$store.registerModule(namespace, _store);
      that.$store.commit('addModules', namespace.replace(/\//, ''));
    }
  }
};

window.$mapActions = (that, module, array) => {
  if (typeof module !== 'string') {
    array = module;
    module = '';
  }

  const namespace = `${that.$store.getters.getStoreNm}${module ? '/' + module : ''}`;
  array.forEach(e => {
    if (!that.$store._actions[namespace + '/' + e]) {
      that.$options.methods[e] = () => {};
    } else {
      const objs = e.split('/');
      const methodName = objs[objs.length - 1];
      e.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
      that.$store._actions[namespace + '/' + e].forEach(action => {
        that.$options.methods[methodName] = action;
      });
    }
  });
};

window.$mapMutations = (that, module, array) => {
  if (typeof module !== 'string') {
    array = module;
    module = '';
  }

  const namespace = `${that.$store.getters.getStoreNm}${module ? '/' + module : ''}`;
  array.forEach(e => {
    if (!that.$store._mutations[namespace + '/' + e]) {
      that.$options.methods[e] = () => {};
    } else {
      const objs = e.split('/');
      const methodName = objs[objs.length - 1];
      that.$store._mutations[namespace + '/' + e].forEach(mutation => (that.$options.methods[methodName] = mutation));
    }
  });
};

window.$mapGetters = (that, module, obj) => {
  let temp = {};
  if (typeof module !== 'string') {
    obj = module;
    module = '';
  }

  const namespace = `${that.$store.getters.getStoreNm}${module ? '/' + module : ''}`;

  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      if (typeof that.$store.getters[namespace + '/' + obj[i]] == 'undefined') {
        temp[obj[i]] = () => {};
      }
    }
  } else {
    for (let key in obj) {
      if (typeof that.$store.getters[namespace + '/' + obj[key]] == 'undefined') {
        temp[obj[key]] = () => {};
      }
    }
  }

  that.$options.computed = {
    ...that.$options.computed,
    ...mapGetters(namespace, obj),
  };

  for (let key in temp) {
    that.$options.computed[key] = temp[key];
  }
};

window.$getCode = async (code, excludeKeys) => {
  let codes = store.getters['code2/getCode'](code, excludeKeys);
  if (codes) {
    return codes;
  } else {
    await store.dispatch('code2/searchCode', code);
    return $getCode(code, excludeKeys);
  }
};

window.$getCodeNm = async (uCode, dCode, excludeKeys) => {
  let codeNm;
  const codeList = await $getCode(uCode, excludeKeys);
  codeList.some(item => {
    if (item.code == dCode) {
      codeNm = item.codeNm;
      return true;
    }
  });

  if (!codeNm) codeNm = dCode;
  return codeNm;
};

window.$getCodeIBSheetFormat = async (code, excludeKeys) => {
  let codes = await $getCode(code, excludeKeys);
  if (0 < codes.length) {
    let obj = {};
    codes.forEach(arr => (obj[arr.code] = arr.codeNm));
    return obj;
  } else {
    return {};
  }
};

window.$getCodeIBSheetEnum = async (type, code, options) => {
  let excludeKeys = undefined;
  let defaultKey = '';
  if (options) {
    if (options.excludeKeys) {
      excludeKeys = options.excludeKeys;
    }
    if (options.defaultKey !== undefined) {
      defaultKey = '|' + options.defaultKey;
    } else if (options.defaultKey === '') {
      defaultKey = '|' + options.defaultKey;
    }
  }

  let codes = await $getCode(code, excludeKeys);
  if (0 < codes.length) {
    if (type == 'KEY') return codes.reduce((prev, curr) => prev + '|' + curr.code, defaultKey);
    if (type == 'VALUE') return codes.reduce((prev, curr) => prev + '|' + curr.codeNm, defaultKey);
    if (type != 'KEY' && type != 'VALUE') return '';
  } else {
    return '';
  }
};

window.$getConstants = key => {
  return CodeConstants[key];
};

const codeConstantsPlugin = (CodeConstants.install = Vue => {
  Vue.prototype.$getConstants = key => {
    return CodeConstants[key];
  };
});
Vue.use(codeConstantsPlugin);

window.$openMenu = (that, mnuCd) => {
  that.$store.dispatch('menu/onOpenMenu', mnuCd);
};

window.$externalData = data => {
  store.dispatch('setExternalData', data);
};

router.beforeEach((to, from, next) => {
  store.commit('setCurrentUrl', to);

  let isNext = false;
  for (let key in store.getters.menuAuthCache) {
    if (key == to.path) {
      next();
      isNext = true;
      store.commit('mdi/setCurrentUrls', store.getters.menuAuthCache[key]);

      break;
    }
  }
  if (!isNext) next();
});

Vue.mixin({
  props: {
    storeNm: {
      type: String,
      default: store.getters.getStoreNm,
    },
    eventNms: {
      type: Object,
      default: function _default() {
        return {};
      },
    },
  },
  data() {
    return {
      location: {go: this.go, back: this.back},
    };
  },
  computed: {
    ...mapGetters('commonButton', {
      searchBtn: 'searchBtn',
      newBtn: 'newBtn',
      saveBtn: 'saveBtn',
      deleteBtn: 'deleteBtn',
      printBtn: 'printBtn',
      closeBtn: 'closeBtn',
      commonCanSearch: 'canSearch',
      commonCanNew: 'canNew',
      commonCanSave: 'canSave',
      commonCanDelete: 'canDelete',
      commonCanPrint: 'canPrint',
    }),
    userInfo() {
      return JSON.parse(sessionStorage.getItem('userInfo'));
    },
    pgmCd() {
      return (
        this.$store.getters['pgmCd'][this.$store.getters['currentUrl']] ||
        JSON.parse(sessionStorage.getItem('userInfo')).pgmCd
      );
    },
    prjCd() {
      return this.getPrjCd();
    },
    cud: {
      set(cud) {
        this.callEvent({name: 'setCud', param: cud});
      },
      get() {
        return this.$store.getters['cud'][this.$store.getters['currentUrl']] || 0;
      },
    },
    canSearch: {
      set(canSearch) {
        setTimeout(() => {
          if (this._activated) {
            this.setSearch(canSearch);
          }
        }, 10);
      },
      get() {
        return this.commonCanSearch;
      },
    },
    canNew: {
      set(canNew) {
        setTimeout(() => {
          if (this._activated) {
            this.setNew(canNew);
          }
        }, 10);
      },
      get() {
        return this.commonCanNew;
      },
    },
    canSave: {
      set(canSave) {
        setTimeout(() => {
          if (this._activated) {
            this.setSave(canSave);
          }
        }, 10);
      },
      get() {
        return this.commonCanSave;
      },
    },
    canDelete: {
      set(canDelete) {
        setTimeout(() => {
          if (this._activated) {
            this.setDelete(canDelete);
          }
        }, 10);
      },
      get() {
        return this.commonCanDelete;
      },
    },
    canPrint: {
      set(canPrint) {
        setTimeout(() => {
          if (this._activated) {
            this.setPrint(canPrint);
          }
        }, 10);
      },
      get() {
        return this.commonCanPrint;
      },
    },
    isSimpleExec() {
      return !!this.userInfo.simpleExec;
    },
  },
  created() {
    this._activated = true;
    const recomputed = Object.create(null);
    const watchers = this._computedWatchers; // Warning: Vue internal
    if (!watchers) return;

    for (const key in watchers) this.makeRecomputable(watchers[key], key, recomputed);

    this.$recompute = key => (recomputed[key] = !recomputed[key]);
    Vue.observable(recomputed);
    if (this.$options._url && this.$options._url == this.$store.getters.currentUrl) {
      store.commit('setLoadVue', this);
    }
  },
  updated() {
    if (this._sheet) {
      this._sheet.calculate(1);
    }
  },
  activated() {
    this._activated = true;
  },
  deactivated() {
    this._activated = false;
  },
  methods: {
    //TODO mdi 의 removeMdi 동일한 코드이므로 정리필요!!
    //  모듈화하여 mdi.js와 여기서 같이 사용하도록!!
    go(no, param) {
      if (no === undefined || no >= 0) return;
      const urls = this.$store.getters['mdi/urls'];
      const history = this.$store.getters['mdi/history'];
      if (!urls.length) return;
      const rootUrl = urls.find(url => url.isSelected).url;
      if (window.subVue && window.subVue[rootUrl]) {
        for (let i = no; i < 0; i++) {
          let length = window.subVue[rootUrl].length;
          length--;
          window.subVue[rootUrl][length].$destroy();
          window.subVue[rootUrl].splice(length, 1);

          if (!length) {
            delete window.subVue[rootUrl];
          }
          const historyLength = history[rootUrl].length;

          if (historyLength > 1) {
            const url = history[rootUrl][historyLength - 1].url;
            router.replace({name: url.replace(/\//g, ''), path: url, params: param || {}}, () => {
              this.$store.dispatch('mdi/popHistory', rootUrl);
            });
          } else {
            router.replace({name: rootUrl.replace(/\//g, ''), path: rootUrl, params: param || {}}, () => {
              this.$store.dispatch('mdi/popHistory', rootUrl);
            });
          }
        }
      }
    },
    back() {
      this.go(-1);
    },

    makeRecomputable(watcher, key, recomputed) {
      const original = watcher.getter;
      recomputed[key] = true;
      watcher.getter = vm => (recomputed[key], original.call(vm, vm));
    },

    addEvent: function(event) {
      const me = this;
      if (Object.prototype.toString.call(event).slice(8, -1) == 'Array') {
        for (let key in event) {
          if (Object.keys(me.eventNms).length) {
            for (let eKey in me.eventNms) {
              if (eKey == event[key].name) {
                event[key].name = me.eventNms[eKey];
              }
            }
          }

          if (event[key].isDefault) {
            bus.$off(event[key].name);
            bus.$on(event[key].name, event[key].func);
          } else {
            bus.$off(event[key].name + me.$store.getters.currentUrl);
            bus.$on(event[key].name + me.$store.getters.currentUrl, event[key].func);
          }
        }
      } else {
        if (Object.keys(me.eventNms).length) {
          for (let eKey in me.eventNms) {
            if (eKey == event.name) {
              event.name = me.eventNms[eKey];
            }
          }
        }

        if (event.isDefault) {
          bus.$off(event.name);
          bus.$on(event.name, event.func);
        } else {
          bus.$off(event.name + me.$store.getters.currentUrl);
          bus.$on(event.name + me.$store.getters.currentUrl, event.func);
        }
      }
    },

    callEvent: function(event) {
      if (event.isDefault) {
        bus.$emit(event.name, event.param);
      } else {
        bus.$emit(event.name + this.$store.getters.currentUrl, event.param);
      }
    },

    goMenu: async function(menu1, paramObj) {
      let mnuCd = MenuConstants[menu1];
      if (!mnuCd) return;

      const menuList = this.$store.getters['menu/menu'];
      let menu;

      const deep = items => {
        if (!items || !items.length) {
          return;
        }
        items.some(item => {
          if (item.mnuCd == mnuCd) {
            menu = item;
            return true;
          }
          deep(item.Items);
        });
      };

      deep(menuList);

      if (menu) {
        if (paramObj) {
          this.$store.dispatch('setExternalData', paramObj);
        }
        if (!menu.authList) {
          const response = await axios.post('/menu/selectMenuAuthList', {
            pgmCd: this.pgmCd,
            mnuCd: menu.mnuCd,
          });
          menu.authList = response.data;
        }
        this.$store.dispatch('mdi/addMDI', menu);
      }
    },

    goVue: function(url, paramObj) {
      if (this.subVue) {
        const urls = this.subVue.find(e => e.url == url);

        let isDup = false;
        for (let key in this.$store.getters['mdi/history']) {
          this.$store.getters['mdi/history'][key].forEach(el => {
            if (el.name == urls.name) isDup = true;
          });
        }

        if (!isDup) {
          if (!this.$store.getters['mdi/router'].filter(e => e.url === urls.url).length) {
            const component = getComponent(urls.path);
            component._url = urls.url;
            this.$router.matcher.addRoutes([{name: urls.name, path: urls.url, component: component}]);
            this.$store.commit('mdi/ADD_ROUTER', urls);
          }
        }

        router.replace({name: urls.name, path: urls.url, params: {...paramObj}}, to => {
          if (!window.subVue) window.subVue = [];
          const rootUrl = this.$store.getters['mdi/urls'].filter(url => url.isSelected)[0].url;
          setTimeout(() => {
            to.matched.some(item => {
              if (item.path == url) {
                if (!window.subVue[rootUrl]) window.subVue[rootUrl] = [];
                window.subVue[rootUrl].push(item.instances.default);
                return true;
              }
            });
          }, 10);

          this.$store.dispatch('mdi/setLocation', urls);

          childViewAuthSetup.call(this, urls.path);
        });
      }
    },

    addComponent: function(name, path) {
      if (path.indexOf('@') == 0) {
        path = path.replace('@', '.');
      }
      Vue.component(name, getComponent(path));
    },
    $modalConfirm(e, btnIndex = 0) {
      if (e && e.kind != 'Data') return;
      let cnt = 0;
      const parent = vue => {
        cnt++;
        if (cnt > 10) return;
        if (vue.$parent.classes && vue.$parent.classes.indexOf('__modal__') != -1) {
          if (vue.$parent.$parent.btns[btnIndex] && typeof vue.$parent.$parent.btns[btnIndex].callback == 'function') {
            vue.$parent.$parent.btns[btnIndex].callback();
          }
        } else {
          parent(vue.$parent);
        }
      };
      parent(this);
    },
    setCud(cud) {
      this.callEvent({name: 'setCud', param: cud});
    },
    getPrjCd() {
      const prjInfo = this.getPrjInfo();
      return store.getters['prjCd'] || prjInfo?.prjCd || prjInfo?.prjEstCd;
    },
    getPrjInfo() {
      return store.getters['project/loadPrj'][this.$store.getters['currentUrl']];
    },
    ...mapActions('project', ['addFuncPrj']),
    ...mapActions('commonButton', [
      'setSearch',
      'setNew',
      'setSave',
      'setDelete',
      'setPrint',
      'addFuncSearch',
      'addFuncNew',
      'addFuncSave',
      'addFuncDelete',
      'addFuncPrint',
    ]),
  },
});

Vue.config.errorHandler = function(err, vm, info) {
  // 에러 핸들링
  // err;
  // vm;
  // info;
  // `type`은 Vue의 에러 타입입니다. 예: 라이프사이클 훅
  // 2.2.0+ 이상에서 사용할 수 있습니다
  console.group(`Vue Error Handler`);
  console.error(err);
  console.error(vm);
  console.error(info);
  console.groupEnd();
  alert(`에러가 발생했습니다. \n개발자도구에서 에러를 확인하여 주세요. \n${err}`);
};

Vue.config.warnHandler = function(msg, vm, trace) {
  // trace는 컴포넌트 계층 구조를 추적합니다.
  console.group(`Vue Warn Handler`);
  console.warn(msg);
  console.warn(vm);
  console.warn(trace);
  console.groupEnd();
  alert(`경고가 발생했습니다. \n개발자도구에서 경고를 확인하여 주세요. \n${msg}`);
};

new Vue({
  router,
  store,
  created() {
    window.$modal = this.$modal;
    this.doBackControl();
    this.doCommonComponentReg();
    window.addEventListener('beforeunload', this.beforeunload);
  },
  computed: {
    ...mapGetters('mdi', {
      urls: 'urls',
      history: 'history',
    }),
  },
  methods: {
    beforeunload(e) {
      if (sessionStorage.getItem('userInfo')) {
        let LoginLogVo = {
          pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          empNo: JSON.parse(sessionStorage.getItem('userInfo')).empNo,
        };
        axios.post('/loginLog/updateLogoutHistory', LoginLogVo);
      }

      if (validSheet() || validParameter()) {
        e.returnValue = '';
      }
    },

    /** 공통컴포넌트등록 */
    doCommonComponentReg() {
      const requireComponent = require.context(
        // 컴포넌트들이 있는 폴더
        './components',
        // 하위 폴더까지 포함할 지 여부
        true,
        // 기본 컴포넌트를 찾는데 사용할 정규표현식
        /[A-Z-]\w+\.(vue|js)$/
      );

      requireComponent.keys().some(fileName => {
        if (!fileName.endsWith('IbSheet.js') && fileName.endsWith('Sheet.js')) {
          return false;
        }
        // 컴포넌트 설정 가져오기
        const componentConfig = requireComponent(fileName);

        // 컴포넌트의 파스칼표기법 이름 가져오기
        const componentName = kebabCase(
          // 폴더 위치와 무관하게 파일이름 추출
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        );

        // 컴포넌트를 전역적으로 등록
        Vue.component(
          componentName,
          // `export default`를 이용한 컴포넌트는 `.default`로 컴포넌트
          // 옵션을 추출하고 그렇지 않은 컴포넌트는 모듈의 루트를 호출
          componentConfig.default || componentConfig
        );
      });
    },
    /** 뒤로가기 제어 */
    doBackControl() {
      window.onpopstate = function() {
        history.go(1);
      };
    },
  },
  render: h => h(App),
}).$mount('#app');
