import Vue from 'vue';
import store from '@/store/index.js';
const vue = new Vue();
const validation = () => {
  let isValid = true;
  let isChecked = true;
  let modalSelector = document.querySelectorAll('.__modal__').length ? '.__modal__ ' : '';
  const requireds = Array.from(
    document.querySelectorAll(
      `${modalSelector}[required]:not(.datepicker), ${modalSelector}[required] .mx-input.required`
    )
  );
  let invalidEl;
  let group = {};
  let title, message;
  requireds.some(el => {
    if (group[el.name]?.checked) {
      return false;
    }
    if (['checkbox', 'radio'].indexOf(el.type) !== -1) {
      group[el.name] = {
        title: el.dataset.errorTitle,
        message: el.dataset.errorMessage || '유효성 검증실패',
        checked: el.checked,
      };
    }
    if (el.classList.contains('iui__select')) {
      if (el.dataset.value === '') {
        isValid = false;
        invalidEl = el;
        return true;
      }
    } else if (el.value === '') {
      isValid = false;
      invalidEl = el;
      return true;
    }

    if (el.minLength && el.minLength > el.value.length) {
      isValid = false;
      let message = `${el.minLength}${el.minLength === el.maxLength ? '자리로 입력주세요' : '자 이상 입력해주세요.'}`;
      el.dataset.errorMessage = message;
      invalidEl = el;
      return true;
    }
  });

  for (let key in group) {
    if (!group[key].checked) {
      title = group[key].title;
      message = group[key].message;
      isChecked = false;
      break;
    }
  }
  if (!isChecked) {
    vue.$alert({title: title, message: message});
    return isChecked;
  }

  if (invalidEl !== undefined) {
    title = invalidEl.dataset.errorTitle;
    message = invalidEl.dataset.errorMessage || '유효성 검증실패';
    vue.$alert({title: title, message: message}, () => {
      setTimeout(() => {
        invalidEl.focus();
      }, 100);
    });
  }
  return isValid;
};

const fileSave = () => {
  let totalCnt = 0;
  let curruentCnt = 0;
  let totalProgressObj = {};
  const fileComps = store.getters.fileCompMap;
  if (!fileComps) {
    return {};
  }
  fileComps.forEach(item => {
    totalCnt += Object.keys(item.interface.progressInfo).length;
  });

  return new Promise((resolve, reject) => {
    const setProgress = fileInterface => {
      if (fileInterface.progressInfo[fileInterface.currentId]) {
        return (totalProgressObj[fileInterface.currentId] = parseFloat(
          fileInterface.progressInfo[fileInterface.currentId].progress
        ));
      } else {
        return 0.0;
      }
    };
    const getFileNm = fileInterface => {
      if (fileInterface.progressInfo[fileInterface.currentId]) {
        return fileInterface.progressInfo[fileInterface.currentId].name;
      } else {
        return '';
      }
    };
    const sumTotalProgress = totalProgressObj => {
      let total = 0;
      for (let key in totalProgressObj) {
        total += totalProgressObj[key];
      }
      return total;
    };
    const upload = (index = 0, fileResultMap = {}) => {
      try {
        const next = () => {
          if (index < fileComps.length - 1) {
            upload(++index, fileResultMap);
          } else {
            setTimeout(() => {
              vue.$fileProgress.hide();
              resolve(fileResultMap);
            }, 500);
          }
        };
        const fileComponent = fileComps[index];
        const fileInterface = fileComps[index].interface;
        fileInterface.state = state => {
          try {
            const progress = setProgress(fileInterface);
            const totalProgress = sumTotalProgress(totalProgressObj);
            const fileNm = getFileNm(fileInterface);
            switch (state) {
              case -1: //에러
                reject(fileInterface.error);
                setTimeout(() => {
                  vue.$fileProgress.hide();
                }, 500);

                break;
              case 0: //
                next();
                break;
              case 1:
                vue.$fileProgress.setProgress({
                  currentCnt: curruentCnt,
                  totalCnt: totalCnt,
                  progress: progress,
                  totalProgress: totalProgress,
                  fileNm: fileNm,
                });
                break;
              case 2:
                curruentCnt++;
                break;
              case 3:
                fileResultMap[fileComponent.id] = {
                  flNo: fileInterface.flNo,
                  files: fileInterface.files,
                  flNoCol: fileComponent.flNoCol,
                };

                vue.$fileProgress.setProgress({
                  currentCnt: curruentCnt,
                  totalCnt: totalCnt,
                  progress: progress,
                  totalProgress: totalProgress,
                  fileNm: fileNm,
                });
                fileComponent.initInterface();
                next();
                break;
            }
          } catch (error) {
            vue.$fileProgress.hide();
            reject(error);
          }
        };
        fileInterface.save();
      } catch (ex) {
        vue.$fileProgress.hide();
        reject(ex);
      }
    };
    if (totalCnt) {
      vue.$fileProgress.show();
      upload();
    } else {
      resolve({});
    }
  });
};

const save = async (url, param = {}, options = {}) => {
  if (!validation()) {
    return {status: 999};
  }
  if (options.afterValidation && !(await options.afterValidation())) {
    return {status: 999};
  }
  let fileInfo = await fileSave();
  if (options.callback instanceof Function) {
    const _param = await options.callback(fileInfo, param);
    if (!_param) {
      return {status: 999};
    }
    param = _param;
  } else {
    for (let key in fileInfo) {
      param[fileInfo[key].flNoCol] = fileInfo[key].flNo;
    }
  }

  return axios.post(url, param);
};

window.$save = save;
window.$validation = validation;
