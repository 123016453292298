export default {
  INCOME_STATUS_BY_MID: 'MNG0203', // 공종별손익현황,

  APP_STANDBY: 'GRP0201', // 결재대기
  APP_SEND_STANDBY: 'GRP0301', // 발송대기
  APP_RECEIPT_STANDBY: 'GRP0401', // 접수대기
  APP_PUBLIC_STATE: 'GRP0501', // 공람현황

  SCHEDULE: 'GRP0702', // 일정등록
  NOTICE: 'SYS0402', // 공지사항
  RFQ_MAT_NOTICE: 'MAT0201', // 자재입찰공고
  RFQ_SUB_NOTICE: 'WOK0201', // 외주입찰공고

  RFQ_MAT_CUSTOMER: 'BMT0101', // 입찰등록 (협력사)
  RFQ_SUB_CUSTOMER: 'BWK0101', // 입찰등록 (협력사)

  JOIN_APPROVAL: 'BAS0102', // 회원가입등록 (운영사)
  PURCHS_CNSLT_RECEIPT: 'BAS0101', // 구매상담신청접수 (운영사)
  QnA_ANSWER: 'SYS0405', // 질의답변등록 (운영사)

  EQUIP_CODE_REGIST: 'SYS0105', // 장비코드등록

  CERT_REGIST_CTR: 'CTR0204', // 인증서등록 (계약기성관리)
  CERT_REGIST_MAT: 'MAT0307', // 인증서등록 (자재관리)
  CERT_REGIST_WOK: 'WOK0307', // 인증서등록 (외주관리)
  CERT_REGIST_BMT: 'BMT0501', // 인증서등록 (협력사 - 자재)
  CERT_REGIST_BWK: 'BWK0401', // 인증서등록 (협력사 - 외주)
};
